import React from 'react';

import { Box, Text, Grid, Container } from 'components';
import { useIntl } from 'context/IntlContext';
import compliance from './icons/complian.png';
import help from './icons/help.png';
import emi from './icons/emi.png';

const YouCanTrust = () => {
  const { t } = useIntl();

  return (
    <>
      <Text
        variant="sectionTitle"
        sx={{
          textAlign: 'center',
        }}
      >
        {t('homepage.home.trust_section.title')}
      </Text>
      <Grid
        columns={['1fr', '1fr', '1fr 1fr 1fr', '1fr 1fr 1fr']}
        gap={12}
        sx={{
          pt: 14,
        }}
      >
        <Box>
          <Box as="picture">
            <img width="100%" alt="help" src={help} />
          </Box>
          <Text
            variant="contentTitle"
            sx={{
              pt: 9,
            }}
          >
            {t('homepage.home.trust_section.help_item.title')}
          </Text>
          <Text
            variant="underContent"
            sx={{
              fontWeight: 'normal',
              pt: 2,
            }}
          >
            {t('homepage.home.trust_section.help_item.subtitle')}
          </Text>
          <Text
            variant="underContent"
            sx={{
              fontWeight: 'semibold',
              color: 'primary',
            }}
          />
        </Box>
        <Box>
          <Box as="picture">
            <img width="100%" alt="compliance" src={compliance} />
          </Box>

          <Text variant="contentTitle" sx={{ pt: 9 }}>
            {t('homepage.home.trust_section.compliance_item.title')}
          </Text>
          <Text
            variant="underContent"
            sx={{
              fontWeight: 'normal',
              pt: 2,
            }}
          >
            {t('homepage.home.trust_section.compliance_item.subtitle')}
          </Text>
        </Box>
        <Box>
          <Box as="picture">
            <img width="100%" alt="emi" src={emi} />
          </Box>
          <Text variant="contentTitle" sx={{ pt: 9 }}>
            {t('homepage.home.trust_section.emi_item.title')}
          </Text>
          <Text
            variant="underContent"
            sx={{
              fontWeight: 'normal',
              pt: 2,
            }}
          >
            {t('homepage.home.trust_section.emi_item.subtitle')}
          </Text>
        </Box>
      </Grid>
    </>
  );
};

export default YouCanTrust;
