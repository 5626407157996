import React, { FC, ReactElement } from 'react';

import { Box, Text, Heading, Grid } from 'components';

import Section from '../Section';
import { useIntl } from 'react-intl';
interface TopCTAProps {
  image: ReactElement;
  heading: string;
  subtitle: string;
}

import SafeBrokLogo from '../../components/Logo/icons/safeBrok.svg';

const TopCTA: FC<TopCTAProps> = ({ image, heading, subtitle, children }) => {
  const { locale } = useIntl();

  const isSpanishPage = locale === 'es';

  return (
    <Section
      outterSX={{ bg: 'tertiaryBackground' }}
      innerSX={{
        pb: [0, 0, 0, 0, 0],
      }}
      noDivider
    >
      <Grid gap={14} columns={['3fr', '3fr', '3fr', '1fr 1fr']}>
        <Box>
          <Heading
            as="h1"
            sx={{
              variant: 'text.heroTitle',
              mb: [0, 0, 0, 70, 35],
            }}
          >
            <SafeBrokLogo 
              width="70"
            /><tr/>
            {heading}
          </Heading>
          <Text
            as="p"
            variant="underHero"
            sx={{
              opacity: '0.8',
            }}
          >
            {subtitle}
          </Text>
        </Box>
        <Box
          sx={{
            height: ['auto', 'auto', 'auto', 514, 514],
            display: ['none', 'none', 'none', 'inline-block', 'inline-block'],
            position: 'relative',
            width: '100%',
            verticalAlign: 'middle',
            overflow: 'hidden',
            '& > svg': {
              verticalAlign: 'bottom',
            },
          }}
        >
          <Box sx={{ position: 'absolute', bottom: 0 }}>{image}</Box>
        </Box>
      </Grid>
      {children}
    </Section>
  );
};

export default TopCTA;
