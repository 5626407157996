import React from 'react';

import {
  Button,
  Text,
  Box,
  Grid,
  Image,
} from 'components';

import Link from 'components/Link';

import { useIntl } from 'context/IntlContext';

import Clock from '../../../images/icons/clock.svg';
import Manage from '../../../images/icons/manage.svg';
import Cash from '../../../images/icons/cash.svg';
import meetDigitalProfileImage from '../../../images/meetYourNewDigitalProfile.png';

const DigitalAccountSection = () => {
  const { t } = useIntl();

  return (
    <Grid
      columns={['1fr', '1fr', '1fr', '1fr 1fr']}
      sx={{
        columnGap: 14,
      }}
    >
      <Box
        sx={{
          display: 'block',
          gridColumn: '1',
          mb: [11, 11, 11, 0],
          gridRow: ['2', '2', '2', '1/4'],
          '& > svg': {
            verticalAlign: 'text-bottom',
            maxWidth: '100%',
          },
        }}
      >
      <Image
        src={meetDigitalProfileImage}
        alt="Meet Digital Profile"
        sx={{
          marginTop: '18px',
        }}
      />
      </Box>
      <Box
        sx={{
          gridColumn: ['1', '1', '1', '2'],
          gridRow: '1',
          mb: [9, 9, 9, 12],
        }}
      >
        <Text mb={5} variant="sectionTitle">
          {t('homepage.home.digital_account_section.title') ||
            ' Meet your new digital account.'}
        </Text>
        <Text
          variant="underHero"
          sx={{
            opacity: '0.8',
          }}
        >
          {t('homepage.home.digital_account_section.subtitle') ||
            'SafeBrok gives you simple and easy access to all sort of financial solutions through our digital account.'}
        </Text>
      </Box>
      <Box
        sx={{
          gridColumn: ['1', '1', '1', '2'],
          gridRow: ['3', '3', '3', '2'],
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Clock />
          <Text
            variant="contentTitle"
            sx={{
              fontWeight: 'semibold',
              pl: 20,
            }}
          >
            {t('homepage.home.digital_account_section.signup_item.title') ||
              ' Sign-up in 5 minutes'}
          </Text>
        </Box>

        <Text
          variant="underContent"
          sx={{
            fontWeight: 'normal',
            maxWidth: 452,
            mt: [18],
          }}
        >
          {t('homepage.home.digital_account_section.signup_item.subtitle') ||
            'Personal and Business Accounts available. Open an account easily under 5 minutes.'}
        </Text>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            pt: 12,
          }}
        >
          <Manage />

          <Text
            variant="contentTitle"
            sx={{
              fontWeight: 'semibold',
              pl: 20,
            }}
          >
            {t('homepage.home.digital_account_section.manage_item.title') ||
              ' Manage everything from one single place.'}
          </Text>
        </Box>
        <Text
          variant="underContent"
          sx={{
            fontWeight: 'normal',
            maxWidth: 452,
            mt: [18],
          }}
        >
          {t('homepage.home.digital_account_section.manage_item.subtitle')}
        </Text>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            pt: 12,
          }}
        >
          <Cash />
          <Text
            variant="contentTitle"
            sx={{
              fontWeight: 'semibold',
              pl: 20,
            }}
          >
            {t('homepage.home.digital_account_section.free_item.title')}
          </Text>
        </Box>
        <Text
          variant="underContent"
          sx={{
            fontWeight: 'normal',
            maxWidth: 452,
            mt: [18],
          }}
        >
          {t('homepage.home.digital_account_section.free_item.subtitle')}
        </Text>
      </Box>
    </Grid>
  );
};

export default DigitalAccountSection;
